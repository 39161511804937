import { DISTRIBUTION_MODES } from '../consts';
import { MENU_PAGE_ORITATION_ENUMS, TARGET_DISPLAY_SIZE } from 'src/consts/menuDecorate';
import TabletFieldPathConst from 'src/components/Settings/SmartOrdering/TabletFieldPathConst';
import { getFieldValueByNamePath } from './object';
import { convertTimestampToZonedTime } from './utils';
import { WEEKDAYS } from 'src/components/MenuManagement/MenuBuilder/const';

const DATE_FORMAT = 'YYYY-MM-DD';

const { LANDSCAPE, PORTRAIT } = MENU_PAGE_ORITATION_ENUMS;
const { showCategoryForLandscapeMenuDecrationNamePath, showCategoryForPortraitMenuDecorationNamePath } =
  TabletFieldPathConst;
const MENU_OPTIONS_MENU_HOURS = 'menu_hour';

export function sortMenuList(rawMenuList) {
  return rawMenuList.sort((a, b) => {
    const { distribution_mode: mod1, sequence: seq1 } = a;
    const { distribution_mode: mod2, sequence: seq2 } = b;
    if (mod1 === DISTRIBUTION_MODES.SMART_ORDERING && mod2 !== DISTRIBUTION_MODES.SMART_ORDERING) return 1;
    if (mod1 !== DISTRIBUTION_MODES.SMART_ORDERING && mod2 === DISTRIBUTION_MODES.SMART_ORDERING) return -1;
    return seq1 - seq2;
  });
}

export function detectHotspotIntersection(hotspots) {
  if (hotspots.length < 2) return false;

  for (let i = 0; i < hotspots.length - 1; i++) {
    const hotspot1 = hotspots[i];
    const { x: left1, y: top1, width: width1, height: height1 } = hotspot1.position;
    const right1 = left1 + width1;
    const bottom1 = top1 + height1;

    for (let j = i + 1; j < hotspots.length; j++) {
      const hotspot2 = hotspots[j];
      const { x: left2, y: top2, width: width2, height: height2 } = hotspot2.position;
      const right2 = left2 + width2;
      const bottom2 = top2 + height2;

      if (bottom1 < top2 || bottom2 < top1 || left1 > right2 || right1 < left2) continue;
      return true;
    }
  }

  return false;
}

export function extractMealBriefInfo(meal) {
  const { id, meal_prototype, price, member_price, menu, category, point_price, is_open_priced } = meal;
  return {
    id,
    name: meal_prototype.name,
    foreign_name: meal_prototype.foreign_name,
    price,
    member_price,
    menu,
    category,
    point_price,
    is_open_priced,
  };
}

export function checkIfRestaurantEnableTabletPro(restaurantInfo) {
  const { restaurant_contracts = [] } = restaurantInfo;
  if (!restaurant_contracts.length) return false;

  const contract = restaurant_contracts.find((_) => _.distribution_mode === DISTRIBUTION_MODES.SMART_ORDERING);
  if (!contract) return false;

  const { smart_ordering_for_tablet, smart_ordering_for_tablet_pro } = contract;
  return smart_ordering_for_tablet && smart_ordering_for_tablet_pro;
}

const { WIDTH, HEIGHT } = TARGET_DISPLAY_SIZE;

export function getSizeByOrientation(orientation) {
  if (orientation === PORTRAIT) {
    return { width: HEIGHT, height: WIDTH };
  }

  return { width: WIDTH, height: HEIGHT };
}

export function getHotspotsOfDisplayingPage({ pageId, pageInfoMap, componentsMap }) {
  if (!pageId) return [];
  const pageInfo = pageInfoMap[pageId];
  if (!pageInfo) return [];
  const { component_ids } = pageInfo;
  if (!component_ids) return [];
  return component_ids.reduce((prev, id) => {
    const component = componentsMap[id];
    if (component) prev.push(component);
    return prev;
  }, []);
}

export function getParamsFromLocation(location) {
  if (!location) return {};

  const segments = location.split('-');

  if (segments.length === 2) {
    const [menuId, pageId] = segments;
    return { menuId, pageId };
  }

  if (segments.length === 3) {
    const [menuId, categoryId, pageId] = segments;
    return { menuId, categoryId, pageId };
  }

  return {};
}

export function getShowCategoryForCurrentMenuDecorationMode({ orientation, preference }) {
  if (!orientation) return false;
  if (orientation === LANDSCAPE)
    return getFieldValueByNamePath(preference, showCategoryForLandscapeMenuDecrationNamePath);
  if (orientation === PORTRAIT)
    return getFieldValueByNamePath(preference, showCategoryForPortraitMenuDecorationNamePath);
  return false;
}

/**
 * 判断扫码点餐菜单是否是否Menu Hours
 * @param {Object} restaurantInfo 餐厅信息
 */
export function detectSmartOrderingMenuOptionsIsMenuHours(restaurantInfo) {
  const smartOrderingContract = (restaurantInfo.restaurant_contracts || []).find(
    (_) => _.distribution_mode === DISTRIBUTION_MODES.SMART_ORDERING
  );
  return smartOrderingContract?.hour_option === MENU_OPTIONS_MENU_HOURS;
}

/***
 * 获取某个菜单当天的menu hours
 * @param {MenuHour[]} menuHours 菜单整周的menu hours
 * @returns MenuHour[]
 */
export function getMenuHoursOfToday(menuHours) {
  const now = convertTimestampToZonedTime(new Date());
  const dayOfWeek = now.day() - 1;
  const normalMenuHours = menuHours.filter((menuHour) => WEEKDAYS.indexOf(menuHour.day_of_week) === dayOfWeek);
  if (!normalMenuHours.length) {
    return [{ start_at: '00:00', end_at: '23:59', active: true }];
  }
  return normalMenuHours.filter((item) => item.active);
}

/***
 * 从营业时间里获取某个菜单当天的menu hours
 * @param {MenuHour[]} businessHours 营业时间
 * @returns MenuHour[]
 */
export function getMenuHourFromBusinessHours(businessHours) {
  const now = convertTimestampToZonedTime(new Date());
  const dayOfWeek = now.day() - 1;
  const normalMenuHours = businessHours.filter((menuHour) => WEEKDAYS.indexOf(menuHour.weekday) === dayOfWeek);
  if (!normalMenuHours.length) {
    return [{ start_at: '00:00', end_at: '23:59', active: true }];
  }
  return normalMenuHours.reduce((acc, menuHourItem) => {
    if (menuHourItem.enabled) {
      acc.push({
        start_at: menuHourItem.from,
        end_at: menuHourItem.to,
        active: menuHourItem.enabled,
      });
    }
    return acc;
  }, []);
}

/***
 * 获取某个菜单当天的特殊menu hours
 * @param {SpecialHour[]} menuHourOverrides 菜单所有的特殊的menu hours
 * @returns SpecialHour[]
 */
export function getSpecialMenuHoursOfToday(menuHourOverrides = []) {
  if (!menuHourOverrides.length) return menuHourOverrides;

  const now = convertTimestampToZonedTime(new Date());
  const dateStr = now.format(DATE_FORMAT);
  const specialMenuHours = [];
  menuHourOverrides.sort((a, b) => a.start_at.localeCompare(b.start_at));
  menuHourOverrides.forEach(({ start_at, end_at, all_day_closed }) => {
    const start = convertTimestampToZonedTime(Date.parse(start_at));
    const end = convertTimestampToZonedTime(Date.parse(end_at));
    const startDate = start.format(DATE_FORMAT);
    const sTime = start.format('HH:mm');
    const eTime = end.format('HH:mm');

    if (dateStr === startDate) {
      specialMenuHours.push({
        start_at: sTime,
        end_at: eTime,
        active: !all_day_closed,
      });
    }
  });
  return specialMenuHours;
}

/***
 * 获取某个餐厅网站菜单跟随的营业时间
 * @param {BusinessHour[]} businessHours 餐厅网站营业时间
 * @returns MenuHour[]
 */
export function getFollowedMenuHours(businessHours = []) {
  if (!businessHours.length) return [];

  const now = convertTimestampToZonedTime(new Date());
  const specialMenuHours = [];
  const dateStr = now.format(DATE_FORMAT);
  businessHours.forEach((businessHour) => {
    const { from_date, to_date, from, to, all_day_closed } = businessHour;
    if (dateStr >= from_date && dateStr <= to_date) {
      specialMenuHours.push({
        start_at: from,
        end_at: to,
        active: !all_day_closed,
      });
    }
  });
  return specialMenuHours;
}
