import Router from 'next/router';
import { MENU_BUILDER_DISTRIBUTION_MODES, MENU_ACTION_TARGES, MENU_RESOURCE_ACTIONS, MENU_ROUTE_QUERY } from './const';

const { ADD, ADD_EXISTING, EDIT } = MENU_RESOURCE_ACTIONS;
const { OPEN_MENU_IDS, OPEN_CHANNEL_IDS } = MENU_ROUTE_QUERY;
const PRESERVED_PARAMS = [OPEN_MENU_IDS, OPEN_CHANNEL_IDS];
const PLATFORM_FUANTUAN = 'fantuan';
const PLATFORM_HUNGRYPANDA = 'hungrypanda';

/**
 * 修改菜单编辑器当前路径
 * @param {String} path 当前路径
 * @param {Boolean} addFromQuery url是否增加跳转前路径参数
 */
export function changeMenuBuilderPath(path, addFromQuery = false) {
  const oldQuery = Router.query;
  const query = PRESERVED_PARAMS.reduce((result, param) => {
    result[param] = oldQuery[param];
    return result;
  }, {});
  query.path = path;
  query.from = addFromQuery ? Router.asPath : '';
  Router.push({
    pathname: Router.pathname,
    query,
  });
}

export function sortByContractAndMenuSequence(meals) {
  meals.sort((a, b) => {
    const { menu: menu1 } = a;
    const { menu: menu2 } = b;
    let compare =
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(menu1.restaurant_contract.distribution_mode) -
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(menu2.restaurant_contract.distribution_mode);
    if (compare === 0) compare = (menu1.sequence || 0) - (menu2.sequence || 0);
    return compare;
  });
}

export function validateMeal(meal) {
  if (!meal) return false;

  const { approved_at, restaurant_contract, menu } = meal;
  if (!approved_at || !restaurant_contract) return false;
  const { activated_at } = restaurant_contract;
  if (!activated_at || !menu || menu.id === '0') return false;

  return true;
}

/***
 * 按合约/菜单逐级排序
 * @param {Meal[]} meals 菜品数组
 * 1. 按合约排序
 * 2. 当合约相同时，按菜单sequence升序排序
 *    2.1 当菜单sequence相同时，按菜单id升序排序
 */
export function sortByContractAndMenuId(meals) {
  meals.sort((a, b) => {
    const { menu: menu1 } = a;
    const { menu: menu2 } = b;
    let compare =
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(menu1.restaurant_contract?.distribution_mode) -
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(menu2.restaurant_contract?.distribution_mode);
    if (compare === 0) compare = menu1.id - menu2.id;
    if (compare === 0) compare = (menu1.sequence || 0) - (menu2.sequence || 0);
    if (compare === 0) compare = menu1.id - menu2.id;
    return compare;
  });
}

/***
 * 按合约/菜单/分类逐级排序
 * @param {Meal[]} meals 菜品数组
 * 1. 按合约排序
 * 2. 当合约相同时，按菜单sequence升序排序
 *    2.1 当菜单sequence相同时，按菜单id升序排序
 * 3. 当菜单排序相同时，按分类sequence升序排序，
 *    3.1 当匪类sequence相同时，按分类id升序排序
 */
export function sortByContractAndMenuAndCategorySequence(meals) {
  meals.sort((a, b) => {
    const { menu: menu1, restaurant_contract: contract1, category: category1 } = a;
    const { menu: menu2, restaurant_contract: contract2, category: category2 } = b;
    let compare =
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(contract1.distribution_mode) -
      MENU_BUILDER_DISTRIBUTION_MODES.indexOf(contract2.distribution_mode);
    if (compare === 0) compare = (menu1.sequence || 0) - (menu2.sequence || 0);
    if (compare === 0) compare = menu1.id - menu2.id;
    if (compare === 0) compare = (category1.sequence || 0) - (category2.sequence || 0);
    if (compare === 0) compare = category1.id - category2.id;
    return compare;
  });
}

export function mergeCustomizationColumnsForMeals(meals) {
  const mergeCache = {};
  const firstMealOfMenus = {};
  let menuId;

  meals.forEach((meal) => {
    const { menu } = meal;

    if (!menuId || menu.id !== menuId) {
      menuId = menu.id;
      firstMealOfMenus[menu.id] = meal;
      meal.merged = false;
      meal.rowSpan = 1;
    } else {
      mergeCache[menu.id] = (mergeCache[menu.id] || 1) + 1;
      meal.merged = true;
      meal.rowSpan = 1;
    }
  });

  Object.keys(mergeCache).forEach((menuId) => {
    const rowSpan = mergeCache[menuId] || 1;
    const meal = firstMealOfMenus[menuId];
    if (meal) meal.rowSpan = rowSpan;
  });
}

/**
 * 解析菜单导航路径
 * @param {import { ParsedUrlQuery } from 'querystring'} query
 * @return Object
 */
export function parsePathParams(query) {
  const { path } = query;
  let distributionMode;
  let menuId;
  let categoryId;
  let actionName;
  let actionTarget;
  let mealPrototypeId;

  if (path) {
    const segments = path.split('/');
    const { length } = segments;

    switch (length) {
      // top actions, like search search?searchTerm=**
      case 1: {
        actionTarget = MENU_ACTION_TARGES.TOP;
        const decodedPath = decodeURIComponent(path);
        actionName = decodedPath.split('?')[0];
        break;
      }

      // Contract related :distributionMode/:actionName
      case 2: {
        distributionMode = segments[0];
        actionName = segments[1];
        actionTarget = MENU_ACTION_TARGES.CONTRACT;
        break;
      }

      // Menu related :distributionMode/:menuId/:actionName
      case 3: {
        distributionMode = segments[0];
        menuId = segments[1];
        actionName = segments[2];
        actionTarget = MENU_ACTION_TARGES.MENU;
        break;
      }

      // Category related :distributionMode/:menuId/:categoryId/:actionName
      case 4: {
        distributionMode = segments[0];
        menuId = segments[1];
        categoryId = segments[2];
        actionName = segments[3];
        actionTarget = MENU_ACTION_TARGES.CATEGORY;
        break;
      }

      // Meal related :distributionMode/:menuId/:categoryId/meal/(add/:id)
      default: {
        distributionMode = segments[0];
        menuId = segments[1];
        categoryId = segments[2];
        mealPrototypeId = segments[4]; // add or number 如果meal prototype id 为add，则为新增
        actionTarget = MENU_ACTION_TARGES.MEAL;
        actionName = [ADD, ADD_EXISTING].includes(mealPrototypeId) ? mealPrototypeId : EDIT;
      }
    }
  }

  return {
    distributionMode,
    menuId,
    categoryId,
    actionName,
    actionTarget,
    mealPrototypeId,
    currentPath: path,
  };
}

/***
 * 获取搜索关键字
 * @param {String} path 当前路径
 */
export function getSearchTerm(path) {
  const segments = (path || '').split('?');
  const searchSegment = segments[1];
  if (!searchSegment) return '';
  const [_, searchTerm] = searchSegment.split('=');
  return searchTerm || '';
}

/**
 * 获取合约下的菜单
 * @param {Object} params
 * @param {MenuBuilderReducer} params.menuBuilder 菜单编辑器store
 * @param {String} params.distributionMode 合约名
 * @param {Boolean} params.includeCategories 是否包含分类
 * @return Menu[]
 */
export function getMenusOfContract({ menuBuilder, distributionMode, includeCategories = false }) {
  const { menusGroupedByContract, menuCacheMap } = menuBuilder;
  const contract = menusGroupedByContract[distributionMode];
  if (!contract) return [];
  return (contract.menu_ids || []).reduce((acc, menuId) => {
    const menu = menuCacheMap[menuId];
    if (menu) {
      const copiedMenu = { ...menu };
      if (includeCategories) {
        const { categoryCacheMap } = menuBuilder;
        const categories = menu.category_ids.reduce((result, categoryId) => {
          const category = categoryCacheMap[categoryId];
          if (category) result.push(category);
          return result;
        }, []);
        copiedMenu.categories = categories;
      }
      acc.push(copiedMenu);
    }
    return acc;
  }, []);
}

/**
 * 根据平台名获取中文显示名
 * @param {String} platformName
 * @returns String 显示名
 */
export function getPlatformForeignName(platformName) {
  const lowerCasePlatformName = (platformName || '').toLowerCase();
  if (lowerCasePlatformName === PLATFORM_FUANTUAN) return '饭团';
  if (lowerCasePlatformName === PLATFORM_HUNGRYPANDA) return '熊猫';
  return platformName;
}
